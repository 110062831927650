import { render, staticRenderFns } from "./Grid.vue?vue&type=template&id=e5b45182&scoped=true&"
import script from "./Grid.vue?vue&type=script&lang=js&"
export * from "./Grid.vue?vue&type=script&lang=js&"
import style0 from "./Grid.vue?vue&type=style&index=0&id=e5b45182&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5b45182",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EventCard: require('/home/ticketshoplv/public_html/ic3peak.tour.tickets/components/Event/Card.vue').default,EventCardSkeleton: require('/home/ticketshoplv/public_html/ic3peak.tour.tickets/components/Event/CardSkeleton.vue').default})
